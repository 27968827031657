// extracted by mini-css-extract-plugin
export var blocksContainer = "payload-module--blocks-container--0edbc";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--14148";
export var blocksInRowsWithOneActiveInnerWrapperTwo = "payload-module--blocks-in-rows-with-one-active-inner-wrapper-two--ec70b";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--06906";
export var blocksInRowsWithOneActiveSectionTwo = "payload-module--blocks-in-rows-with-one-active-section-two--ebe26";
export var blocksRedirectingToResourcesSubtitle = "payload-module--blocks-redirecting-to-resources-subtitle--9e006";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--f1f96";
export var coloredBlocksContainer = "payload-module--colored-blocks-container--11e74";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--d87eb";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--7ad98";
export var commonOuterWrapper = "payload-module--common-outer-wrapper--f7cd4";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--b5beb";
export var contactButtonTextSection = "payload-module--contact-button-text-section--8dd97";
export var contactButtonTextSectionInnerWrapper = "payload-module--contact-button-text-section-inner-wrapper--95d47";
export var customSubtitle = "payload-module--custom-subtitle--13499";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--0f3bd";
export var headerTitle = "payload-module--header-title--f47bb";
export var headerTitleBorderWidth = "payload-module--header-title-border-width--d4a9f";
export var imageContainer = "payload-module--image-container--cedc5";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--a05fe";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--4e59b";
export var opacitySliderVisibleText = "payload-module--opacity-slider-visible-text--10eae";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--1d739";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--cc7a6";
export var sectionUnderHeaderInnerWrapper = "payload-module--section-under-header-inner-wrapper--695c4";
export var sectionUnderHeaderToggleVisibilityButton = "payload-module--section-under-header-toggle-visibility-button--fc654";
export var singleBlockContainer = "payload-module--single-block-container--c9cb5";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--4e2d4";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--0b3a7";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--4b9d6";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--191b2";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--74092";
export var titlesContainer = "payload-module--titles-container--b4d4b";